/**
 * Support app
 * Support app
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CreateTicketEventResponseAttachmentChangesInner } from './createTicketEventResponseAttachmentChangesInner';
import { CreateTicketEventResponseProductIdInner } from './createTicketEventResponseProductIdInner';
import { CreateTicketEventResponseConversationChangesInner } from './createTicketEventResponseConversationChangesInner';
import { CreateTicketEventResponseFieldChangesInner } from './createTicketEventResponseFieldChangesInner';


export interface CreateTicketEventResponse { 
    customerId?: string;
    notificationType?: CreateTicketEventResponse.NotificationTypeEnum;
    ticketId?: string;
    createdAt?: string;
    productId?: Array<CreateTicketEventResponseProductIdInner>;
    fieldChanges?: Array<CreateTicketEventResponseFieldChangesInner>;
    conversationChanges?: Array<CreateTicketEventResponseConversationChangesInner>;
    attachmentChanges?: Array<CreateTicketEventResponseAttachmentChangesInner>;
}
export namespace CreateTicketEventResponse {
    export type NotificationTypeEnum = 'TICKET_UPDATED' | 'TICKET_CREATED';
    export const NotificationTypeEnum = {
        Updated: 'TICKET_UPDATED' as NotificationTypeEnum,
        Created: 'TICKET_CREATED' as NotificationTypeEnum
    };
}


