import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TicketProduct } from '../../api/v1';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  constructor(private translateService: TranslateService) {}

  getMainProductDisplayName(products: TicketProduct[]): string {
    const mainProduct = products.find((product: TicketProduct) => product.main);

    if (!mainProduct) {
      return '-';
    }

    if (mainProduct.description) {
      return mainProduct.description;
    }

    const equipmentNotFoundText = this.translateService.instant('TICKETS.EQUIPMENT-NOT-FOUND') as string;
    return `${mainProduct.registeredProductId} ${equipmentNotFoundText}`;
  }
}
