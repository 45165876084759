import { Inject, Injectable } from '@angular/core';
import { ApiService } from '@gea/digital-ui-lib';
import { HttpClient } from '@angular/common/http';
import { MsalBroadcastService } from '@azure/msal-angular';
import { Store } from '@ngxs/store';

@Injectable({
  providedIn: 'root',
})
export class AssetApiService extends ApiService {
  constructor(
    @Inject('assetBaseUrl') protected override baseUrl: string,
    @Inject('subscriptionKey') protected override subscriptionKey: string,
    protected msalBroadacast: MsalBroadcastService,
    protected override http: HttpClient,
    protected override store: Store
  ) {
    super(baseUrl, subscriptionKey, msalBroadacast, http, store);
  }
}
