/**
 * Support app
 * Support app
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type InitiatorCode = 'NotFound' | 'InboundToGea' | 'OutboundFromGea';

export const InitiatorCode = {
    NOT_FOUND: 'NotFound' as InitiatorCode,
    INBOUND_TO_GEA: 'InboundToGea' as InitiatorCode,
    OUTBOUND_FROM_GEA: 'OutboundFromGea' as InitiatorCode
};

