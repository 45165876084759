import { Component } from '@angular/core';

import { ColumnRendererComponent } from '@gea/digital-ui-lib';
import { ServiceCategory, Ticket } from '../../../api/v1';

@Component({
  selector: 'advance-status-renderer',
  templateUrl: './status-renderer.component.html',
})
export class StatusRendererComponent implements ColumnRendererComponent<Ticket> {
  ServiceCategory = ServiceCategory;
  rowData?: Ticket;
}
