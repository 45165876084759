import { Injectable } from '@angular/core';

import { BannerService, GeaMessage } from '@gea/digital-ui-lib';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class ContactIdBannerService {
  readonly BANNER_KEY = 'contactIdBanner';

  constructor(
    private bannerService: BannerService,
    private translateService: TranslateService
  ) {}

  show(): void {
    this.bannerService.clear(this.BANNER_KEY);
    this.bannerService.add({
      key: this.BANNER_KEY,
      severity: 'warn',
      detail: this.translateService.instant('ALERT.WARNING.MISSING-CONTACT-ID') as string,
      data: {
        hasCloseButton: true,
      },
    } as GeaMessage);
  }
}
