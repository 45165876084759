/**
 * Support app
 * Support app
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { InitiatorCode } from './initiatorCode';
import { Attachment } from './attachment';


export interface ConversationInteraction { 
    objectId: string;
    initiatorCode: InitiatorCode;
    processingTypeCode: string;
    creationDateTime: string;
    fromPartyId: string;
    fromPartyName: string;
    fromPartyEmail: string;
    text: string;
    formattedText: string;
    interactionAttachments: Array<Attachment>;
}
export namespace ConversationInteraction {
}


