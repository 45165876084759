import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';
import {
  ApiErrorResponse,
  ButtonTypeEnum,
  DialogV2Service,
  ErrorHandlerV2Service,
  SelectOption,
  SnackbarService,
  ToggleV2Component,
  UserFileV2,
} from '@gea/digital-ui-lib';
import { catchError, concatMap, EMPTY, forkJoin, map, of, ReplaySubject, take, takeUntil } from 'rxjs';

import { TicketsService } from '../services/tickets.service';
import { ServiceCategoryService } from '../services/service-category.service';
import { AttachmentsService } from '../services/attachments.service';
import { PermissionsService } from '../services/permissions.service';
import { SupportGoogleAnalyticsService } from '../services/support-google-analytics.service';
import { ProductSelectionService } from '../services/product-selection.service';
import { Product, ProductSelection, ProductSelectionOrganization } from '../models/product-selection.model';
import { AttachmentBinary, CreateTicketRequest, ServicePriority, SupportAppPermissions, Ticket } from '../../api/v1';
import { ENVIRONMENT_CONFIG, EnvironmentConfiguration } from '../../../environments/models/environment.model';

@Component({
  selector: 'advance-create-ticket',
  templateUrl: './create-ticket.component.html',
  styleUrls: ['./create-ticket.component.scss'],
})
export class CreateTicketComponent implements OnInit, OnDestroy {
  @ViewChild('toggleComponent', { static: false }) toggleComponent: ToggleV2Component | undefined;

  formLoading = false;
  createButtonLoading = false;
  createTicketForm!: FormGroup;
  ButtonType = ButtonTypeEnum;

  predecessorTicketId?: string;
  initialSelectedCategory: string | undefined;

  categorySelectOptions!: SelectOption[];
  registeredProductIdSelectOptions!: SelectOption[];
  userOrganizations: SelectOption[] = [];
  userOrganizationsAndProducts: ProductSelectionOrganization[] = [];
  newAttachments: AttachmentBinary[] = [];
  newTicket!: CreateTicketRequest;
  attachments: UserFileV2[] = [];

  ticketData: Ticket | undefined;
  ticketId: string | undefined;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  submitted = false;
  generateSubject = true;
  subjectLeft = '';
  subjectRight = '';
  autoGeneratedSubject = '';
  equipmentDisabled = true;
  isSubjectDisabled = true;
  isOrganizationDisabled = false;
  hasDescriptionValue = false;
  showAttachmentSideModal = false;
  hasCreatePermission = false;
  loading = false;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private ticketsService: TicketsService,
    private errorHandlerService: ErrorHandlerV2Service,
    private snackbarService: SnackbarService,
    public translateService: TranslateService,
    private serviceCategoryService: ServiceCategoryService,
    public attachmentsService: AttachmentsService,
    public googleAnalyticsService: SupportGoogleAnalyticsService,
    private permissionsService: PermissionsService,
    private dialogService: DialogV2Service,
    private changeDetection: ChangeDetectorRef,
    private productSelectionService: ProductSelectionService,
    @Inject(ENVIRONMENT_CONFIG) private environment: EnvironmentConfiguration
  ) {
    this.initializeCreateTicketForm();
    this.registeredProductIdSelectOptions = [];
  }

  ngOnInit(): void {
    this.formLoading = true;

    this.route.queryParamMap
      .pipe(
        take(1),
        map((params: ParamMap) => params.get('predecessorTicketId'))
      )
      .subscribe({
        next: (predecessorTicketId) => {
          if (predecessorTicketId) {
            this.predecessorTicketId = predecessorTicketId;
          }
        },
      });

    this.permissionsService
      .getPermissions()
      .pipe(take(1))
      .subscribe({
        next: (permissions: SupportAppPermissions) => {
          this.hasCreatePermission = permissions.ticketCreatable;
          if (this.hasCreatePermission) {
            this.loadFormData();

            if (this.googleAnalyticsService.loggedInUser) {
              this.googleAnalyticsService.metaPush('Create-Ticket');
            } else {
              // eslint-disable-next-line no-console
              console.warn('was not able to send ga event');
            }
          } else {
            this.dialogService.open({
              title: 'UI-LIB.ROUTE-GUARD.TITLE',
              message: this.translateService.instant('UI-LIB.ROUTE-GUARD.CONTENT') as string,
              yes: 'UI-LIB.ROUTE-GUARD.BACK-TO-DASHBOARD',
              no: '',
              closable: true,
              hideButtons: false,
              showRejectButton: false,
              confirmCallback: this.confirmNavigateToPortal.bind(this),
            });
          }
        },
        error: (error: ApiErrorResponse) => this.errorHandlerService.handleErrorWithPrefix(error, 'SUPPORT'),
      });
  }

  confirmNavigateToPortal() {
    void this.router.navigate([this.environment.portal.redirectURL]);
  }

  loadFormData() {
    const userOrganizationsAndProducts$ = this.productSelectionService.getSelectedProducts().pipe(
      take(1),
      takeUntil(this.destroyed$),
      catchError((e: HttpErrorResponse) => {
        this.formLoading = false;
        this.errorHandlerService.handleErrorWithPrefix(e, 'SUPPORT');
        return of(undefined);
      })
    );

    // Is only needed for reopening a ticket
    const predecessorTicket$ = this.predecessorTicketId
      ? this.ticketsService.getTicket(this.predecessorTicketId).pipe(
          take(1),
          takeUntil(this.destroyed$),
          catchError((e: HttpErrorResponse) => {
            this.formLoading = false;
            this.errorHandlerService.handleErrorWithPrefix(e, 'SUPPORT');
            return of(undefined);
          })
        )
      : of(undefined);

    const serviceCategoryNames$ = this.serviceCategoryService.getServiceCategories().pipe(
      take(1),
      takeUntil(this.destroyed$),
      catchError((e: HttpErrorResponse) => {
        this.formLoading = false;
        this.errorHandlerService.handleErrorWithPrefix(e, 'SUPPORT');
        return of(undefined);
      })
    );

    forkJoin([userOrganizationsAndProducts$, predecessorTicket$, serviceCategoryNames$]).subscribe({
      next: ([userOrganizationsAndProducts, ticket, serviceCategoryNames]) => {
        if (userOrganizationsAndProducts) {
          this.initializeUserOrganizationsAndProducts(userOrganizationsAndProducts);
        }

        if (serviceCategoryNames) {
          this.categorySelectOptions = serviceCategoryNames.map((categoryName) => ({
            value: categoryName,
            nameKey: 'TICKETS.SERVICE-CATEGORY.' + categoryName,
          }));
        }

        if (ticket) {
          this.ticketData = ticket;

          if (this.ticketData?.organizationId) {
            this.createTicketForm.get('organizationId')?.disable();
            this.createTicketForm
              .get('organizationId')
              ?.setValue(this.userOrganizations.find((organization) => organization.value === this.ticketData?.organizationId));
            this.onOrgaChanged();
            const mainProduct = this.ticketData?.products?.find((product) => product.main);
            if (mainProduct) {
              const registeredProductIdSelectOption = this.registeredProductIdSelectOptions.find(
                (registeredProduct) => registeredProduct.value === mainProduct?.registeredProductId
              );
              if (registeredProductIdSelectOption) {
                this.createTicketForm.get('registeredProductId')?.setValue(registeredProductIdSelectOption);
              }
            }
          }
          this.createTicketForm.get('predecessorTicketId')?.setValue(this.ticketData?.id);
          this.createTicketForm
            .get('serviceCategory')
            ?.setValue(this.categorySelectOptions.find((category) => category.value === this.ticketData?.serviceCategory));
          this.createTicketForm.get('description')?.setValue(this.ticketData?.description);
          this.initialSelectedCategory = 'general';
        }
        this.formLoading = false;
        this.changeDetection.detectChanges();
        this.onEquipmentChanged();
        this.onCategoryChanged();
      },
    });
  }

  initializeUserOrganizationsAndProducts(userOrganizationsAndProducts: ProductSelection) {
    this.userOrganizationsAndProducts = userOrganizationsAndProducts.organizations;
    this.userOrganizations = this.userOrganizationsAndProducts?.map((organization) => ({
      value: organization.organizationId,
      name: organization.organizationName,
    }));

    if (this.userOrganizations.length === 1) {
      this.createTicketForm.get('organizationId')?.setValue(this.userOrganizations[0]);
      this.createTicketForm.get('organizationId')?.disable();
      this.isOrganizationDisabled = true;
      this.onOrgaChanged();
    }
  }

  initializeCreateTicketForm() {
    // that is just how angular forms syntax works
    /* eslint-disable @typescript-eslint/unbound-method */
    this.createTicketForm = this.formBuilder.group({
      subject: new FormControl<string>('', [Validators.required]),
      serviceCategory: new FormControl<string>('', [Validators.required]),
      predecessorTicketId: new FormControl<string>({ value: '', disabled: true }),
      organizationId: new FormControl<string>('', [Validators.required]),
      registeredProductId: new FormControl<string>('', [Validators.required]),
      description: new FormControl<string>('', [Validators.required, Validators.minLength(20), Validators.maxLength(1000)]),
    });
    this.createTicketForm.get('registeredProductId')?.disable();
    this.createTicketForm.get('subject')?.disable();
    const descriptionControl = this.createTicketForm.get('description');
    if (descriptionControl) {
      descriptionControl.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(() => {
        this.hasDescriptionValue = true;
      });
    }
    /* eslint-enable @typescript-eslint/unbound-method */
  }

  onOrgaChanged() {
    const selectedOrga = this.userOrganizationsAndProducts.find(
      // This is just how angular forms syntax works
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      (orga) => this.createTicketForm.getRawValue().organizationId.value === orga.organizationId
    );
    const orgaHasProducts = selectedOrga?.products.length != 0;
    if (selectedOrga && orgaHasProducts) {
      this.registeredProductIdSelectOptions = selectedOrga.products.map((product: Product) => ({
        value: product.id,
        name: `${product.displayName}`,
      }));
      this.registeredProductIdSelectOptions.sort((a, b) => {
        return (a.name || '') > (b.name || '') ? 1 : -1;
      });
      // This is just how angular forms syntax works
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      if (this.createTicketForm.value.registeredProductId) {
        const selectedProduct = this.registeredProductIdSelectOptions.find(
          // This is just how angular forms syntax works
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          (product) => product.value === this.createTicketForm.value.registeredProductId
        );
        if (!selectedProduct) {
          this.createTicketForm.get('registeredProductId')?.setValue('');
        }
      }
      this.createTicketForm.get('registeredProductId')?.enable();
      this.equipmentDisabled = false;
    } else {
      this.createTicketForm.get('registeredProductId')?.disable();
      this.equipmentDisabled = true;
      this.createTicketForm.get('registeredProductId')?.setValue('');
      this.subjectLeft = '';
      this.createTicketForm.get('subject')?.setValue(this.subjectRight);
    }
    this.createTicketForm.get('registeredProductId')?.clearValidators();
  }

  onCategoryChanged() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment
    this.subjectRight = (this.createTicketForm.get('serviceCategory')?.getRawValue()?.name as string) ?? '';
    this.createSubject();
  }

  onEquipmentChanged() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment
    const registeredProductId = this.createTicketForm.get('registeredProductId')?.getRawValue()?.name as string;
    this.subjectLeft = registeredProductId ? registeredProductId + ' / ' : '';
    this.createSubject();
  }

  createSubject() {
    if (this.generateSubject) {
      this.createTicketForm.get('subject')?.disable();
      this.isSubjectDisabled = true;
      this.autoGeneratedSubject = this.subjectLeft + this.subjectRight;
      this.createTicketForm.get('subject')?.setValue(this.autoGeneratedSubject);
    }
  }

  toggleSubjectGeneration() {
    const currentSubject = this.createTicketForm.get('subject')?.getRawValue() as string;

    if (!this.generateSubject && (!currentSubject || currentSubject === this.autoGeneratedSubject)) {
      this.generateSubjectConfirmation();
    } else if (!this.generateSubject) {
      this.dialogService.open({
        title: 'TICKETS.CREATE.DIALOG.DISCARD-CHANGES.CONFIRM',
        message: this.generateMessageForToggleDialog(),
        yes: 'TICKETS.CREATE.DIALOG.DISCARD-CHANGES.ACCEPT',
        no: 'TICKETS.CREATE.DIALOG.DISCARD-CHANGES.REJECT',
        closable: true,
        hideButtons: false,
        showRejectButton: true,
        confirmCallback: this.generateSubjectConfirmation.bind(this),
        rejectCallback: this.generateSubjectRejection.bind(this),
      });
    } else {
      this.generateSubject = !this.generateSubject;
      this.createTicketForm.get('subject')?.enable();
      this.isSubjectDisabled = false;
    }
  }

  generateMessageForToggleDialog(): string {
    return this.isDataFoundForSubject()
      ? (this.translateService.instant('TICKETS.CREATE.GENERATE-TITLE.DIALOG.MESSAGE', {
          subject: `'${this.subjectLeft}${this.subjectRight}'`,
        }) as string)
      : (this.translateService.instant('TICKETS.CREATE.GENERATE-TITLE-DIALOG.DEFAULT.MESSAGE') as string);
  }

  isDataFoundForSubject(): boolean {
    const formElements = ['serviceCategory', 'registeredProductId'];
    for (const element of formElements) {
      if (this.createTicketForm.get(element)?.dirty) {
        return true;
      }
    }
    return false;
  }

  generateSubjectConfirmation() {
    this.generateSubject = true;
    this.createSubject();
  }

  generateSubjectRejection() {
    this.generateSubject = false;
    this.createTicketForm.get('subject')?.enable();
    this.isSubjectDisabled = false;
    this.setToggleState(this.generateSubject);
  }

  setToggleState(value: boolean): void {
    const control = this.toggleComponent?.control;
    if (control) {
      control.setValue(value);
    }
  }

  toggleAttachmentView() {
    this.showAttachmentSideModal = !this.showAttachmentSideModal;
  }

  async updateAttachments(attachments: UserFileV2[]) {
    this.attachments = attachments;
    this.newAttachments = await this.attachmentsService.convertToAttachmentBinary(attachments);
  }

  createTicket() {
    // Disabling because Validators.required does not use 'this'
    // eslint-disable-next-line @typescript-eslint/unbound-method
    this.createTicketForm.get('registeredProductId')?.setValidators(Validators.required);
    this.createTicketForm.get('registeredProductId')?.updateValueAndValidity();
    this.submitted = true;
    if (this.validateTicketForm()) {
      // that is just how angular forms syntax works
      /* eslint-disable @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access */
      const selectedProduct = this.registeredProductIdSelectOptions.find(
        (product) => product?.value === this.createTicketForm?.value?.registeredProductId?.value
      );
      const categoryTranslation = this.translateService.instant(
        `TICKETS.SERVICE-CATEGORY.${this.createTicketForm.value.serviceCategory.value as string}`
      ) as string;
      this.newTicket = {
        serviceCategory: this.createTicketForm.value.serviceCategory.value,
        servicePriority: ServicePriority.URGENT,
        requestedStartDate: new Date().toISOString(),
        description: this.createTicketForm.value.description,
        organizationId: this.createTicketForm.getRawValue().organizationId.value,
        registeredProductId: this.createTicketForm.getRawValue().registeredProductId.value,
        subject: this.isSubjectDisabled
          ? this.createSubjectWithTranslation(categoryTranslation, selectedProduct)
          : this.createTicketForm.value.subject,
        /* eslint-enable @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access */
        predecessorTicketId: this.predecessorTicketId,
      };

      this.createButtonLoading = true;
      this.createTicketForm.controls['serviceCategory'].disable();
      this.createTicketForm.controls['organizationId'].disable();
      this.createTicketForm.controls['registeredProductId'].disable();
      this.createTicketForm.controls['description'].disable();

      const createTicket$ = this.ticketsService.createTicket(this.newTicket).pipe(
        catchError((error: ApiErrorResponse) => {
          this.errorHandlerService.handleErrorWithPrefix(error, 'SUPPORT');
          this.createButtonLoading = false;
          this.createTicketForm.controls['serviceCategory'].enable();
          this.createTicketForm.controls['organizationId'].enable();
          this.createTicketForm.controls['registeredProductId'].enable();
          this.createTicketForm.controls['description'].enable();
          return EMPTY;
        }),
        concatMap((ticket) => {
          this.ticketId = ticket.id;
          this.googleAnalyticsService.createTicketPush(this.newTicket.serviceCategory, ServicePriority.URGENT);
          if (this.newAttachments.length > 0) {
            return this.attachmentsService.addAttachment(ticket.id, this.newAttachments);
          }
          return of(ticket);
        })
      );

      createTicket$.subscribe({
        next: () => {
          if (this.newAttachments.length > 0) {
            this.googleAnalyticsService.createTicketAttachmentPush(
              this.newTicket.serviceCategory,
              this.newAttachments.map((attachment) => attachment.name)
            );
          }
          this.snackbarService.add({
            summary: 'SUCCESS-SNACKBAR.SUCCESS',
            detail: this.translateService.instant('TICKETS.CREATE.SUCCESS') as string,
            severity: 'success',
          });
          void this.router.navigate(['/tickets'], { state: { highlightRow: this.ticketId } });
        },
        error: (error: ApiErrorResponse) => {
          void this.router.navigate([`/tickets/${this.ticketId || ''}`], { state: { attachments: this.attachments } });
          this.errorHandlerService.handleWithCustomError(
            this.translateService.instant('API.ERROR.CREATE-TICKET.ADD-ATTACHMENT') as string,
            error
          );
        },
      });
    }
  }

  navigateToTable() {
    void this.router.navigate(['/tickets']);
  }

  navigateToTableCheck() {
    if (this.createTicketForm.dirty) {
      this.dialogService.open({
        title: 'TICKETS.CREATE.DIALOG.DISCARD-CHANGES.TITLE',
        message: this.translateService.instant('TICKETS.CREATE.DIALOG.DISCARD-CHANGES.MESSAGE') as string,
        yes: 'TICKETS.CREATE.DIALOG.DISCARD-CHANGES.CONFIRM',
        no: 'TICKETS.CREATE.DIALOG.DISCARD-CHANGES.REFUSE',
        closable: true,
        hideButtons: false,
        showRejectButton: true,
        confirmCallback: this.navigateToTable.bind(this),
      });
    } else {
      this.navigateToTable();
    }
  }

  validateTicketForm(): boolean {
    Object.keys(this.createTicketForm.controls).forEach((key) => {
      this.createTicketForm.controls[key].markAsDirty();
    });
    this.createTicketForm.markAllAsTouched();
    this.createTicketForm.updateValueAndValidity();
    return this.createTicketForm.valid;
  }

  createSubjectWithTranslation(category: string, product?: SelectOption): string {
    const productName = product?.name ? `${product.name} / ` : '';
    return `${productName}${category}`;
  }

  uploadFiles(attachments: UserFileV2[]) {
    this.loading = true;
    if (this.ticketId) {
      const ticketId = this.ticketId;
      this.attachmentsService
        .convertToAttachmentBinary(attachments)
        .then((attachments) => {
          this.attachmentsService
            .addAttachment(ticketId, attachments)
            .pipe(concatMap(() => this.attachmentsService.getAttachments(ticketId)))
            .subscribe({
              next: () => {
                this.loading = false;
              },
              error: (err: ApiErrorResponse) => {
                this.loading = true;
                this.handleError(err);
              },
            });
        })
        .catch((error: ApiErrorResponse) => this.handleError(error));
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  private handleError(error: ApiErrorResponse) {
    this.errorHandlerService.handleErrorWithPrefix(error, 'SUPPORT');
    this.loading = false;
  }
}
