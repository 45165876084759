/**
 * Support app
 * Support app
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ServiceCategory } from './serviceCategory';
import { ServicePriority } from './servicePriority';


export interface CreateTicketRequest { 
    subject?: string;
    description: string;
    serviceCategory: ServiceCategory;
    servicePriority: ServicePriority;
    requestedStartDate: string;
    registeredProductId?: string;
    predecessorTicketId?: string;
    organizationId: string;
}
export namespace CreateTicketRequest {
}


