<div class="status-container" *ngIf="map">
  <div class="label">
    <geaui-icon-v2 [file]="(map[status] || map['default']).icon" color="black"></geaui-icon-v2>
    <div class="label-container">
      <span
        class="status-label"
        [pTooltip]="statusName ?? (map[status] || map['default']).label | translate"
        geauiTooltipOnEllipsisV2
        >{{ statusName ?? (map[status] || map['default']).label | translate }}</span
      >
    </div>
  </div>
  <div class="progress">
    <div
      [style.width]="(map[status] || map['default']).progress + '%'"
      [style.backgroundColor]="'var(--' + (map[status] || map['default']).color + ')'"></div>
    <div class="empty" [style.width]="'calc(100% - ' + (map[status] || map['default']).progress + '%)'"></div>
  </div>
</div>
