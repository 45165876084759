import { Router } from '@angular/router';
import { FormatWidth, getLocaleDateFormat, getLocaleTimeFormat } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';

import {
  ApiErrorResponse,
  ColumnDefinition,
  ColumnRendererComponent,
  ContextMenuComponent,
  DateRendererComponent,
  ErrorHandlerV2Service,
  FilterTableSettings,
  HeaderRendererComponent,
  SelectOption,
  SortDirection,
  TableServiceV2,
  TextFilterComponent,
  UserState,
  VisibilitySettingsRendererComponent,
} from '@gea/digital-ui-lib';
import { TranslateService } from '@ngx-translate/core';
import { catchError, concatMap, of, ReplaySubject, take, takeUntil, tap } from 'rxjs';
import { TicketsService } from './services/tickets.service';
import { PermissionsService } from './services/permissions.service';
import { ProductSelectionService } from './services/product-selection.service';
import { ProductSelection } from './models/product-selection.model';
import { ContactIdBannerService } from './services/contact-id-banner.service';
import { StatusRendererComponent } from './ticket-status/status-renderer/status-renderer.component';
import { ProductService } from './services/product.service';
import { SupportAppPermissions, Ticket, TicketList } from '../api/v1';
import { TicketDetails } from './models/ticket-list-entry.model';
import { Store } from '@ngxs/store';

@Component({
  selector: 'advance-tickets',
  templateUrl: './tickets.component.html',
  styleUrls: ['./tickets.component.scss'],
})
export class TicketsComponent implements OnInit, OnDestroy {
  protected readonly SortDirection = SortDirection;
  protected readonly TABLE_ID = 'support-app-ticket-list';
  private tableSettingsUpdated = false;

  hasCreatePermission = false;
  hasMemberships = false;
  hasReadPermission = true;
  ticketColumns: ColumnDefinition[] = [
    {
      key: 'lastChangeDateTime',
      displayName: 'TICKET-LIST.LAST-MODIFIED',
      renderer: {
        component: DateRendererComponent as ColumnRendererComponent<unknown>,
        config: {
          format: 'short',
        },
      },
      width: 170,
    },
    {
      key: 'id',
      displayName: 'TICKET-LIST.TICKET-NR',
      width: 140,
    },
    {
      key: 'subject',
      displayName: 'TICKET-LIST.TITLE',
      width: 500,
    },
    {
      key: 'organization',
      displayName: 'TICKET-LIST.ORGANIZATION',
      width: 300,
    },
    {
      key: 'mainProduct',
      displayName: 'TICKET-LIST.PRODUCT',
      width: 300,
    },
    {
      key: 'serviceCategoryName',
      displayName: 'TICKET-LIST.CATEGORY',
      filter: {
        component: TextFilterComponent,
      },
      width: 250,
    },
    {
      key: 'statusCodeName',
      displayName: 'TICKET-LIST.STATUS',
      filter: {
        component: TextFilterComponent,
      },
      renderer: {
        component: StatusRendererComponent as ColumnRendererComponent<unknown>,
      },
      width: 260,
    },
    {
      displayName: '',
      key: 'settings',
      frozen: 'right',
      sortable: false,
      width: 50,
      headerRenderer: {
        component: VisibilitySettingsRendererComponent as HeaderRendererComponent<unknown>,
        config: {
          columns: [
            {
              key: 'lastChangeDateTime',
              displayName: 'TICKET-LIST.LAST-MODIFIED',
            },
            {
              key: 'id',
              displayName: 'TICKET-LIST.TICKET-NR',
            },
            {
              key: 'subject',
              displayName: 'TICKET-LIST.TITLE',
            },
            {
              key: 'organization',
              displayName: 'TICKET-LIST.ORGANIZATION',
            },
            {
              key: 'mainProduct',
              displayName: 'TICKET-LIST.PRODUCT',
            },
            {
              key: 'serviceCategoryName',
              displayName: 'TICKET-LIST.CATEGORY',
            },
            {
              key: 'statusCodeName',
              displayName: 'TICKET-LIST.STATUS',
            },
          ],
        },
      },
      renderer: {
        component: ContextMenuComponent as ColumnRendererComponent<unknown>,
        config: {
          items: [
            {
              key: 'createPredecessor',
              text: 'TICKET-LIST.ACTION.CREATE-SUCCESSOR-TICKET',
              icon: '16px_copy-2',
              action: 'createPredecessor',
              disabledForFunction: (rowData: Ticket) => rowData.statusCode !== 'Completed_5',
            },
          ],
        },
      },
    },
  ];

  defaultTableSettings = {
    columns: {
      lastChangeDateTime: {
        sort: SortDirection.DESCENDING,
      },
    },
  };
  ticketData: TicketDetails[] = [];
  loading = true;
  entryCount = 0;
  selectedProducts: SelectOption[] = [];
  products: SelectOption[] = [];
  highlightRow = '';
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  searchInput = '';
  filterTableSettings: FilterTableSettings | undefined;

  constructor(
    private tableService: TableServiceV2,
    private translateService: TranslateService,
    private ticketsService: TicketsService,
    private errorHandlerService: ErrorHandlerV2Service,
    private router: Router,
    private permissionsService: PermissionsService,
    private productSelectionService: ProductSelectionService,
    private store: Store,
    private contactIdBannerService: ContactIdBannerService,
    private productService: ProductService
  ) {
    this.translateService.onLangChange.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.reloadTicketData(this.filterTableSettings);
    });
    this.highlightRow = this.router.getCurrentNavigation()?.extras?.state?.['highlightRow'] as string;
  }

  ngOnInit(): void {
    this.store
      // This is the official syntax of ngxs
      // eslint-disable-next-line @typescript-eslint/unbound-method
      .select(UserState.user)
      .subscribe((user) => (this.hasMemberships = (user?.memberships && user?.memberships?.length > 0) ?? false));

    this.permissionsService
      .getPermissions()
      .pipe(take(1))
      .subscribe({
        next: (permissions: SupportAppPermissions) => {
          if (!permissions.hasContactId) {
            this.contactIdBannerService.show();
          }
          this.hasCreatePermission = permissions.ticketCreatable;
        },
        error: (error: ApiErrorResponse) => this.errorHandlerService.handleErrorWithPrefix(error, 'SUPPORT'),
      });

    this.productSelectionService
      .getSelectedProducts()
      .pipe(
        take(1),
        catchError((error: ApiErrorResponse) => {
          this.errorHandlerService.handleErrorWithPrefix(error, 'SUPPORT');
          return of({ organizations: [] } as ProductSelection);
        }),
        tap((productSelection: ProductSelection) => {
          this.updateProductSelections(productSelection);
          this.updateSelectedProductsFilter(this.selectedProducts);
        }),
        concatMap(() => this.tableService.getFilterTableSettings(this.TABLE_ID)),
        takeUntil(this.destroyed$)
      )
      .subscribe({
        next: (tableSettings) => {
          this.filterTableSettings = tableSettings;
          this.searchInput = tableSettings.searchValue ?? '';
          this.tableSettingsUpdated = !!this.searchInput;
          this.reloadTicketData(tableSettings);
        },
        error: (error: ApiErrorResponse) => {
          this.errorHandlerService.handleErrorWithPrefix(error, 'SUPPORT');
        },
      });

    this.tableService.actions.subscribe((action) => {
      if (action.action === 'createPredecessor') {
        const rowData = action.rowData as TicketDetails;
        this.addTicket(rowData.id);
      }
    });
  }

  updateProductSelections(productSelection: ProductSelection) {
    const tmpProducts: SelectOption[] = [];
    const tmpSelectedProducts: SelectOption[] = [];

    productSelection.organizations.forEach((organization) => {
      organization.products.forEach((product) => {
        tmpProducts.push({ value: product.id, name: product.displayName });
        if (product.selected) {
          tmpSelectedProducts.push({ value: product.id, name: product.displayName });
        }
      });
    });

    this.products = tmpProducts;
    this.selectedProducts = tmpSelectedProducts;
  }

  applyFilter(tableFilter: FilterTableSettings) {
    this.reloadTicketData(tableFilter);
  }

  reloadTicketData(tableFilter?: FilterTableSettings) {
    this.loading = true;
    this.ticketsService
      .getTickets(tableFilter)
      .pipe(take(1))
      .subscribe({
        next: this.receiveTicketData.bind(this),
        error: this.receiveError.bind(this),
      });
  }

  receiveTicketData(tickets: TicketList) {
    this.ticketData = tickets.pageEntries.map((pageEntry) => {
      const products = pageEntry.products;
      return {
        ...pageEntry,
        // translateService.get returns an any type and that's okay
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        serviceCategory: this.translateService.instant('TICKETS.SERVICE-CATEGORY.' + pageEntry.serviceCategory),
        mainProduct: this.productService.getMainProductDisplayName(products),
      } as TicketDetails;
    });
    this.entryCount = tickets.entryCount;
    this.loading = false;
  }

  getDateFormat(formatWidth: FormatWidth): string {
    return `${getLocaleDateFormat(this.translateService.currentLang, formatWidth)} ${getLocaleTimeFormat(
      this.translateService.currentLang,
      formatWidth
    )}`;
  }

  receiveError(e: ApiErrorResponse) {
    if (e.error.errorCode === 'SUPPORT_02_0001') {
      this.hasReadPermission = false;
      this.loading = false;
      return;
    }
    this.loading = false;
    this.tableService.setFilterTableSettings(this.TABLE_ID, {
      columns: {},
      multiSelectFilter: this.filterTableSettings?.multiSelectFilter,
    });
    this.errorHandlerService.handleErrorWithPrefix(e, 'SUPPORT');
  }

  onProductsChanged(selectedOptions: SelectOption[]) {
    this.productSelectionService.updateSelectedProducts(selectedOptions);
    this.updateSelectedProductsFilter(selectedOptions);
  }

  onClearSelections() {
    this.productSelectionService.updateSelectedProducts([]);
    this.tableService.updateFilterTableSettings(this.TABLE_ID, {
      columns: {},
      multiSelectFilter: [],
    });
  }

  onSearchTickets() {
    if (!this.searchInput) {
      this.clearSearch();
      return;
    }
    this.updateTableSettings();
  }

  onSearchTicketsByClickEnter(event: KeyboardEvent) {
    if (event.code !== 'Enter') return;
    this.onSearchTickets();
  }

  addTicket(predecessorTicketId?: string) {
    void this.router.navigate(['/tickets/create'], {
      queryParams: { predecessorTicketId },
    });
  }

  onEdit(ticketEntry: Ticket) {
    void this.router.navigate([`/tickets/${ticketEntry.id}`]);
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  clearSearch() {
    if (this.tableSettingsUpdated) {
      this.searchInput = '';
      this.clearTableSettings();
    }
  }

  updateSelectedProductsFilter(selectedProducts: SelectOption[]) {
    this.tableService.updateFilterTableSettings(this.TABLE_ID, {
      columns: {},
      multiSelectFilter: selectedProducts,
      page: 0,
    });
  }

  private updateTableSettings() {
    this.tableService.updateFilterTableSettings(this.TABLE_ID, {
      columns: {},
      search: this.searchInput !== '',
      searchValue: this.searchInput,
      page: 0,
    });
    this.tableSettingsUpdated = true;
  }

  private clearTableSettings() {
    this.tableService.updateFilterTableSettings(this.TABLE_ID, {
      columns: {},
      search: false,
      searchValue: '',
    });
    this.tableSettingsUpdated = false;
  }
}
