<advance-interactive-box
  class="margin-20-bottom"
  *ngIf="hasPredecessorTicket || hasSuccessorTickets"
  (clickIcon)="showPredecessorSuccessorOverlay = true">
  <span class="gea-font-heading-16" data-cy="title">{{ 'TICKETS.DETAIL.RELATED-SUPPORT-REQUEST' | translate }}</span>

  <span class="gea-font-subline-16" *ngIf="hasPredecessorTicket && hasSuccessorTickets" data-cy="subtitle"
    >{{ '1' }} {{ 'TICKETS.DETAIL.PREVIOUS-REQUEST-SHORT' | translate }} {{ ' / ' + successorTickets?.length }}
    {{ 'TICKETS.DETAIL.FOLLOWING-REQUEST-SHORT' | translate }}</span
  >
  <span class="gea-font-subline-16" *ngIf="!hasPredecessorTicket && hasSuccessorTickets" data-cy="subtitle"
    >{{ successorTickets?.length }} {{ 'TICKETS.DETAIL.FOLLOWING-REQUEST-LONG' | translate }}</span
  >
  <span class="gea-font-subline-16" *ngIf="hasPredecessorTicket && !hasSuccessorTickets" data-cy="subtitle"
    >{{ '1' }} {{ 'TICKETS.DETAIL.PREVIOUS-REQUEST-LONG' | translate }}</span
  >
</advance-interactive-box>

<div class="predecessor-successor-overlay" *ngIf="showPredecessorSuccessorOverlay">
  <geaui-icon-v2 [file]="'16px_close'" (click)="showPredecessorSuccessorOverlay = false" color="black"></geaui-icon-v2>
  <div *ngIf="hasPredecessorTicket">
    <span class="gea-font-heading-16 margin-16-bottom" data-cy="title">{{
      'TICKETS.DETAIL.PREVIOUS-REQUEST-LONG' | translate
    }}</span>
    <advance-interactive-box (clickIcon)="handleClickTicket(predecessorTicket?.ticketId ?? '')">
      <span class="gea-font-subline-16" data-cy="title">{{ predecessorTicket?.ticketId }}</span>
      <span class="gea-font-subline-16" [pTooltip]="predecessorTicket?.title ?? ''" data-cy="title">{{
        predecessorTicket?.title
      }}</span>
    </advance-interactive-box>
  </div>

  <div *ngIf="hasSuccessorTickets">
    <span class="gea-font-heading-16 margin-16-bottom" [class.margin-16-top]="hasPredecessorTicket" data-cy="title">{{
      'TICKETS.DETAIL.FOLLOWING-REQUEST-LONG' | translate
    }}</span>

    <advance-interactive-box
      *ngFor="let successorTicket of successorTickets ?? []; index as i"
      [class.margin-8-top]="i !== 0"
      (clickIcon)="handleClickTicket(successorTicket?.ticketId ?? '')">
      <span class="gea-font-subline-16" data-cy="title">{{ successorTicket?.ticketId }}</span>
      <span class="gea-font-subline-16" [pTooltip]="successorTicket?.title ?? ''" data-cy="title">{{
        successorTicket?.title
      }}</span>
    </advance-interactive-box>
  </div>
</div>
