/**
 * Support app
 * Support app
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ConversationInteraction } from './conversationInteraction';


export interface ConversationHistory { 
    objectId: string;
    interactions: Array<ConversationInteraction>;
}

