import { NgModule } from '@angular/core';
import { MsalGuard } from '@azure/msal-angular';
import { RouterModule, Routes } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';

import { TicketsComponent } from './tickets/tickets.component';
import { CreateTicketComponent } from './tickets/create-ticket/create-ticket.component';
import { DetailComponent } from './tickets/detail/detail.component';

const routes: Routes = [
  {
    // Needed for hash routing
    path: 'error',
    component: TicketsComponent,
    canActivate: [MsalGuard],
  },
  {
    // Needed for hash routing
    path: 'state',
    component: TicketsComponent,
    canActivate: [MsalGuard],
  },
  {
    // Needed for hash routing
    path: 'code',
    component: TicketsComponent,
    canActivate: [MsalGuard],
  },
  {
    path: '',
    redirectTo: 'tickets',
    pathMatch: 'full',
  },
  {
    path: 'tickets',
    component: TicketsComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'tickets/create',
    component: CreateTicketComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'tickets/:id',
    component: DetailComponent,
    canActivate: [MsalGuard],
  },
];

const isIframe = window !== window.parent && !window.opener;

@NgModule({
  imports: [
    TranslateModule,
    RouterModule.forRoot(routes, {
      // Don't perform initial navigation in iframes
      initialNavigation: !isIframe ? 'enabledNonBlocking' : 'disabled',
    }),
  ],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
