import { Component, DestroyRef, OnInit } from '@angular/core';
import { ApiErrorResponse, ButtonTypeEnum, ErrorHandlerV2Service, SelectOption, SnackbarService } from '@gea/digital-ui-lib';
import { ProductSelectionService } from '../../tickets/services/product-selection.service';
import { catchError, forkJoin, of, take } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SettingsListItem } from './model/settings-list-item.model';
import { AdministrationApiService, TicketDateFilter, UpdateTicketDateFilter } from '../../api/v1';
import { ComponentCanDeactivate } from '../../guards/discard-changes.guard';

@Component({
  selector: 'advance-request-view-settings',
  templateUrl: './request-view-settings.component.html',
  styleUrl: './request-view-settings.component.scss',
})
export class RequestViewSettingsComponent implements OnInit, ComponentCanDeactivate {
  ButtonType = ButtonTypeEnum;
  changesList: UpdateTicketDateFilter[] = [];
  originalItems: TicketDateFilter[] = [];
  settingsList: SettingsListItem[] = [];
  originalOrgas: SelectOption[] = [];
  userOrganizations: SelectOption[] = [];
  formLoading = true;
  form!: FormGroup;
  sending = false;
  orgasFiltered = false;

  constructor(
    private productSelectionService: ProductSelectionService,
    private destroyed$: DestroyRef,
    private errorHandlerService: ErrorHandlerV2Service,
    private administrationService: AdministrationApiService,
    private snackbarService: SnackbarService
  ) {}

  ngOnInit(): void {
    this.loadData();
    this.form = new FormGroup({});
  }

  loadData() {
    const userOrganizationsAndProducts$ = this.productSelectionService.getSelectedProducts().pipe(
      take(1),
      takeUntilDestroyed(this.destroyed$),
      catchError((e: ApiErrorResponse) => {
        this.formLoading = false;
        this.errorHandlerService.handleErrorWithPrefix(e, 'SUPPORT');
        return of(undefined);
      })
    );

    const savedSettings$ = this.administrationService.getTicketDateFilter().pipe(
      take(1),
      takeUntilDestroyed(this.destroyed$),
      catchError((e: ApiErrorResponse) => {
        this.formLoading = false;
        this.errorHandlerService.handleErrorWithPrefix(e, 'SUPPORT');
        return of(undefined);
      })
    );

    forkJoin([userOrganizationsAndProducts$, savedSettings$]).subscribe({
      next: ([userOrganizationsAndProducts, savedSettings]) => {
        this.userOrganizations =
          userOrganizationsAndProducts?.organizations.map((organization) => ({
            value: organization.organizationId,
            name: organization.organizationName,
          })) ?? [];
        this.originalOrgas = this.userOrganizations;
        this.originalItems = savedSettings ?? [];
        this.settingsList =
          savedSettings?.map((setting, index) => ({
            orgaId: setting.organizationId,
            date: new Date(setting.createdBefore),
            controlName: new Date().getTime().toString() + index,
          })) ?? [];
        this.settingsList.forEach((setting) => {
          this.form.addControl(`organization${setting.controlName}`, new FormControl(setting.orgaId, Validators.required));
          this.form.addControl(`date${setting.controlName}`, new FormControl(setting.date, Validators.required));
        });
        this.formLoading = false;
      },
    });
  }

  addEntry() {
    this.filterOrgas();
    const controlName = new Date().getTime().toString();
    this.form?.addControl(`organization${controlName}`, new FormControl('', Validators.required));
    this.form?.addControl(`date${controlName}`, new FormControl(new Date(), Validators.required));
    this.settingsList.push({
      orgaId: '',
      date: new Date(),
      controlName: controlName,
    });
  }

  // has to be any because of the event type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onOrgaChanged(orgaId: any, index: number) {
    this.settingsList[index].orgaId = orgaId as string;
    const operation = this.changesList.find((change) => change.organizationId === orgaId);
    if (operation) {
      operation.organizationId = orgaId as string;
      if (operation.operation !== 'CREATE') {
        operation.operation = 'UPDATE';
      }
    } else {
      const originalItem = this.originalItems.find((item) => item.organizationId === orgaId);
      this.changesList.push({
        organizationId: orgaId as string,
        createdBefore: this.settingsList[index].date.toISOString(),
        operation: originalItem ? 'UPDATE' : 'CREATE',
      });
    }
    this.filterOrgas();
  }

  onDateChanged(date: Date, index: number) {
    this.settingsList[index].date = date;
    const orgaId = this.settingsList[index].orgaId;
    const operation = this.changesList.find((change) => change.organizationId === orgaId);
    if (operation) {
      operation.createdBefore = date.toISOString();
      if (operation.operation !== 'CREATE') {
        operation.operation = 'UPDATE';
      }
    } else {
      const originalItem = this.originalItems.find((item) => item.organizationId === orgaId);
      this.changesList.push({
        organizationId: this.settingsList[index].orgaId,
        createdBefore: date.toISOString(),
        operation: originalItem ? 'UPDATE' : 'CREATE',
      });
    }
  }

  onDelete(index: number) {
    if (this.sending) return;
    const orgaId = this.settingsList[index].orgaId;
    const controlName = this.settingsList[index].controlName;
    this.form.removeControl(`organization${controlName}`);
    this.form.removeControl(`date${controlName}`);
    this.settingsList.splice(index, 1);
    const originalItem = this.originalItems.find((item) => item.organizationId === orgaId);
    const change = this.changesList.find((change) => change.organizationId === orgaId);
    if (originalItem) {
      if (change) {
        change.operation = 'DELETE';
      } else {
        this.changesList.push({
          organizationId: orgaId,
          createdBefore: originalItem.createdBefore,
          operation: 'DELETE',
        });
      }
    } else if (change) {
      this.changesList.splice(this.changesList.indexOf(change), 1);
    }
    this.filterOrgas();
  }

  isSaveDisabled() {
    return this.settingsList.some((setting) => !setting.orgaId || !setting.date);
  }

  save() {
    this.form.disable();

    this.sending = true;
    this.administrationService.updateTicketDateFilter(this.changesList).subscribe({
      next: () => {
        this.snackbarService.add({
          summary: 'SUCCESS-SNACKBAR.SUCCESS',
          detail: 'TICKETS.FILTER.SUCCESS',
          severity: 'success',
        });
        this.form.enable();
        this.sending = false;
        this.changesList = [];
      },
      error: (e: ApiErrorResponse) => {
        this.errorHandlerService.handleErrorWithPrefix(e, 'SUPPORT');
        this.form.enable();
        this.sending = false;
      },
    });
  }

  filterOrgas() {
    this.orgasFiltered = true;
    this.userOrganizations = this.originalOrgas.filter(
      (orga) => !this.settingsList.some((setting) => setting.orgaId === orga.value)
    );
  }

  canDeactivate() {
    return !this.changesList.length;
  };
}
