import { Component, Input } from '@angular/core';

@Component({
  selector: 'advance-info-box',
  templateUrl: './info-box.component.html',
  styleUrls: ['./info-box.component.scss'],
})
export class InfoBoxComponent {
  @Input() pageTitle = '';
  @Input() hasDivider = true;
  @Input() isLoading = false;
}
