import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'advance-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss'],
})
export class StatusComponent implements OnInit {
  @Input() status = 'default';
  @Input() statusName?: string;
  @Input() isSpareParts = false;

  map?: Record<string, { label: string; icon: string; progress: number; color: string }>;

  ngOnInit() {
    this.map = {
      Open_1: {
        label: 'TICKETS.STATUS.OPEN',
        icon: '16px_open-in-browser',
        progress: 20,
        color: 'geaui-active-blue',
      },
      Planned_Z8: {
        label: this.isSpareParts ? 'TICKETS.STATUS.DISPATCHED' : 'TICKETS.STATUS.SCHEDULED',
        icon: this.isSpareParts ? '16px_delivery-fast' : '16px_calendar',
        progress: 80,
        color: 'geaui-primary',
      },
      WaitingForCustomer_Z9: {
        label: 'TICKETS.STATUS.WAITING',
        icon: '16px_warning-sign',
        progress: 30,
        color: 'geaui-radiant-orange-70',
      },
      Withdrawn_Z3: {
        label: 'TICKETS.STATUS.WITHDRAWN',
        icon: '16px_stop',
        progress: 0,
        color: 'geaui-grey-04',
      },
      Completed_5: {
        label: 'TICKETS.STATUS.COMPLETED',
        icon: '16px_check',
        progress: 100,
        color: 'geaui-tertiary-green-60',
      },
      default: {
        label: 'TICKETS.STATUS.IN_PROCESS',
        icon: '16px_refresh-02',
        progress: 50,
        color: 'geaui-primary',
      },
    };
  }
}
