/**
 * Support app
 * Support app
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ServicePriority = 'NotFound' | 'Immediate' | 'Urgent' | 'Normal' | 'Low';

export const ServicePriority = {
    NOT_FOUND: 'NotFound' as ServicePriority,
    IMMEDIATE: 'Immediate' as ServicePriority,
    URGENT: 'Urgent' as ServicePriority,
    NORMAL: 'Normal' as ServicePriority,
    LOW: 'Low' as ServicePriority
};

