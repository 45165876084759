import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'advance-interactive-box',
  templateUrl: './interactive-box.component.html',
  styleUrls: ['./interactive-box.component.scss'],
})
export class InteractiveBoxComponent {
  @Output() public clickIcon = new EventEmitter<void>();

  handleClickIcon() {
    this.clickIcon.emit();
  }
}
