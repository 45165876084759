<div class="info-box-container" *ngIf="!isLoading; else shadowLoading" data-cy="info-box">
  <div class="info-box-content">
    <span class="gea-font-heading-16 padding-bottom" data-cy="title">{{ pageTitle | translate }}</span>
    <span class="gea-font-subline-16" data-cy="subtitle">
      <ng-content></ng-content>
    </span>
  </div>
  <div [ngClass]="{ divider: hasDivider, space: !hasDivider }"></div>
</div>

<ng-template #shadowLoading>
  <div class="info-box-container">
    <div class="info-box-content">
      <ngx-skeleton-loader [theme]="{ height: '20px', width: '150px' }" count="1" appearance="line"></ngx-skeleton-loader>
      <ngx-skeleton-loader [theme]="{ height: '20px', width: '200px' }" count="1" appearance="line"></ngx-skeleton-loader>
    </div>
  </div>
</ng-template>
