import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { FilterTableSettings, SelectOption } from '@gea/digital-ui-lib';
import {
  ConversationHistory,
  CreateConversationMessageResponse,
  CreateTicketRequest,
  CreateTicketResponse,
  SupportApiService,
  Ticket,
  TicketList,
} from '../../api/v1';

@Injectable({
  providedIn: 'root',
})
export class TicketsService {
  constructor(private api: SupportApiService) {}

  getTickets(tableFilter?: FilterTableSettings): Observable<TicketList> {
    const hasToggle = !!tableFilter?.toggleFilter;
    if (hasToggle) {
      delete tableFilter.toggleFilter;
    }

    const sortBy = this.getSortBy(tableFilter);
    const sortDir = this.getSortDir(tableFilter);
    const products = this.getProducts(tableFilter);
    const filter = this.getFilter(tableFilter);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-return
    return this.api.getTicketList(
      tableFilter?.page,
      tableFilter?.pageSize,
      tableFilter?.searchValue,
      sortBy,
      sortDir,
      filter,
      products
    );
  }

  mapMainProductToProducts(column: string): string {
    //TODO: consider revising this by adding a new attribute to the table that allows the attribute name to be different from the variable name
    if (column === 'mainProduct') {
      return 'products';
    }
    return column;
  }

  getSortBy(tableFilter?: FilterTableSettings): string | undefined {
    if (!tableFilter) {
      return undefined;
    }
    const sortByColumn = Object.keys(tableFilter.columns).find((key) => tableFilter.columns[key].sort);
    return sortByColumn ? this.mapMainProductToProducts(sortByColumn) : undefined;
  }

  getSortDir(tableFilter?: FilterTableSettings): string | undefined {
    if (!tableFilter) {
      return undefined;
    }
    const sortByColumn = Object.keys(tableFilter.columns).find((key) => tableFilter.columns[key].sort);
    return sortByColumn ? tableFilter.columns[sortByColumn].sort : undefined;
  }

  getProducts(tableFilter?: FilterTableSettings): string[] | undefined {
    if (!tableFilter || !tableFilter.multiSelectFilter) {
      return undefined;
    }
    const products: string[] = [];
    tableFilter.multiSelectFilter.forEach((filter: SelectOption<string>) => {
      products.push(filter.value);
    });
    return products ?? undefined;
  }

  getFilter(tableFilter?: FilterTableSettings): string[] | undefined {
    if (!tableFilter || !tableFilter.multiSelectFilter) {
      return undefined;
    }

    const filter: string[] = [];
    Object.entries(tableFilter.columns).forEach(([columnKey, filterList]) => {
      filterList.filter?.forEach((filterValue) => {
        filter.push(`${columnKey}[contains]=${filterValue}`);
      });
    });
    return filter;
  }

  createTicket(newTicket: CreateTicketRequest): Observable<CreateTicketResponse> {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-return
    return this.api.createTicket(newTicket);
  }

  getTicket(ticketId: string): Observable<Ticket> {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-return
    return this.api.getTicket(ticketId);
  }

  getTicketConversation(ticketId: string): Observable<ConversationHistory> {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-return
    return this.api.getTicketConversationList(ticketId);
  }

  createConversationMessage(ticketId: string, message: string): Observable<CreateConversationMessageResponse> {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-return
    return this.api.createTicketConversations(ticketId, { message: message });
  }
}
