/**
 * Support app
 * Support app
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface Attachment { 
    objectId: string;
    parentObjectId?: string;
    fileName: string;
    canDelete?: boolean;
    uploadedByName?: string;
    creationDate?: string;
    lastChangedDate?: string;
}

