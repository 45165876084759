/**
 * Support app
 * Support app
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ServiceCategory = 'Installation_S1000' | 'InstallationAndCommissioning_S2000' | 'Training_S3000' | 'SpareParts_S4000' | 'CorrectiveMaintenance_S5000' | 'PreventativeMaintenance_S6000' | 'ServiceContracts_S7000' | 'Upgrades/ModernizationAndOptimization_S8000' | 'InHouseRepair_S9000' | 'OnSiteProject_SA000' | 'DigitalSolutions_SB000' | 'Complaint_SZ000' | 'DecommissioningAndRecyclingServices_SY000' | 'NotFound';

export const ServiceCategory = {
    INSTALLATION: 'Installation_S1000' as ServiceCategory,
    INSTALLATION_AND_COMMISSIONING: 'InstallationAndCommissioning_S2000' as ServiceCategory,
    TRAINING: 'Training_S3000' as ServiceCategory,
    SPARE_PARTS: 'SpareParts_S4000' as ServiceCategory,
    CORRECTIVE_MAINTENANCE: 'CorrectiveMaintenance_S5000' as ServiceCategory,
    PREVENTATIVE_MAINTENANCE: 'PreventativeMaintenance_S6000' as ServiceCategory,
    SERVICE_CONTRACTS: 'ServiceContracts_S7000' as ServiceCategory,
    UPGRADES_MODERNIZATION_AND_OPTIMIZATION: 'Upgrades/ModernizationAndOptimization_S8000' as ServiceCategory,
    IN_HOUSE_REPAIR: 'InHouseRepair_S9000' as ServiceCategory,
    ON_SITE_PROJECT: 'OnSiteProject_SA000' as ServiceCategory,
    DIGITAL_SOLUTIONS: 'DigitalSolutions_SB000' as ServiceCategory,
    COMPLAINT: 'Complaint_SZ000' as ServiceCategory,
    DECOMMISSIONING_AND_RECYCLING_SERVICES: 'DecommissioningAndRecyclingServices_SY000' as ServiceCategory,
    NOT_FOUND: 'NotFound' as ServiceCategory
};

