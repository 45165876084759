import { Environment as EnvironmentEnum } from '@gea/digital-ui-lib';
import { InjectionToken } from '@angular/core';

export interface Environment {
  production: boolean;
}

//Environment Configurations are stored in the asset folder
export type EnvironmentConfiguration = {
  name?: EnvironmentEnum;
  oAuth: {
    redirectURL: string;
    postLogoutRedirectURL: string;
    clientId: string;
    authorityDomain: string;
    authority: string;
  };
  baseURL: string;
  storageURL: string;
  localizationStorageURL: string;
  iconStorageURL: string;
  appContentStorageURL?: string;
  portal: {
    baseURL?: string;
    redirectURL: string;
  };
  support: {
    baseURL?: string;
  };
  asset: {
    baseURL?: string;
  };
  clarity: {
    projectId: string;
    enabled: boolean;
    clarityLoaderLocation: string;
  };
  cookielawId?: string;
};

export const ENVIRONMENT_CONFIG = new InjectionToken<Environment>('Environment_Configuration');
