import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { SupportApiService, SupportAppPermissions } from '../../api/v1';

@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  private cache$: Observable<SupportAppPermissions> | null = null;

  constructor(private api: SupportApiService) {}

  getPermissions(forceReload = false): Observable<SupportAppPermissions> {
    if (!forceReload && this.cache$) {
      return this.cache$;
    } else {
      return this.api.getPermissions();
    }
  }
}
