import { Injectable } from '@angular/core';

import { GoogleAnalyticsService } from '@gea/digital-ui-lib';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';

@Injectable({
  providedIn: 'root',
})
export class SupportGoogleAnalyticsService extends GoogleAnalyticsService {
  constructor(
    store: Store,
    private translateService: TranslateService
  ) {
    super(store);
  }

  createTicketPush(serviceCategory: string, preferredDate: string): void {
    // Needed like this for Google Analytics
    /* eslint-disable @typescript-eslint/ban-ts-comment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call */
    // @ts-ignore
    window.dataLayer.push({
      event: 'ticket creation',
      eventCategory: 'ticket creation',
      eventAction: this.getServiceCategoryTranslation(serviceCategory),
      eventLabel: this.getPreferredDateTranslation(preferredDate),
    });
    /* eslint-enable @typescript-eslint/ban-ts-comment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call */
  }

  createTicketAttachmentPush(serviceCategory: string, fileNames: string[]): void {
    // Needed like this for Google Analytics
    /* eslint-disable @typescript-eslint/ban-ts-comment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call */
    // @ts-ignore
    window.dataLayer.push({
      event: 'ticket attachment',
      eventCategory: 'ticket attachment',
      eventAction: this.getServiceCategoryTranslation(serviceCategory),
      eventLabel: fileNames.toString(),
    });
    /* eslint-enable @typescript-eslint/ban-ts-comment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call */
  }

  viewTicketPush(ticketNumber: string): void {
    // Needed like this for Google Analytics
    /* eslint-disable @typescript-eslint/ban-ts-comment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call */
    // @ts-ignore
    window.dataLayer.push({
      event: 'ticket detail view',
      eventCategory: 'ticket detail view',
      eventAction: ticketNumber,
      eventLabel: undefined,
    });
    /* eslint-enable @typescript-eslint/ban-ts-comment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call */
  }

  editTicketPush(ticketNumber: string, fieldName: string): void {
    // Needed like this for Google Analytics
    /* eslint-disable @typescript-eslint/ban-ts-comment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call */
    // @ts-ignore
    window.dataLayer.push({
      event: 'ticket edit',
      eventCategory: 'ticket edit',
      eventAction: ticketNumber,
      eventLabel: fieldName,
    });
    /* eslint-enable @typescript-eslint/ban-ts-comment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call */
  }

  getServiceCategoryTranslation(serviceCategory: string): string {
    const translationKey = `TICKETS.SERVICE-CATEGORY.${serviceCategory}`;
    return this.getTranslation(translationKey);
  }

  getPreferredDateTranslation(preferredDate: string): string {
    const translationKey = `TICKETS.REQUEST-CRITICALITY.${preferredDate.toUpperCase()}`;
    return this.getTranslation(translationKey);
  }

  getTranslation(translationKey: string): string {
    const currentLang = this.translateService.currentLang;
    this.translateService.use('en-US');
    const translation = this.translateService.instant(translationKey) as string;
    this.translateService.use(currentLang);

    return translation;
  }
}
