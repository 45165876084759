/**
 * Support app
 * Support app
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AttachmentBinary } from './attachmentBinary';


export interface CreateConversationMessageRequest { 
    message: string;
    attachments?: Array<AttachmentBinary>;
}

