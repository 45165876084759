import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { Attachment, AttachmentBinary, AttachmentsIdentifier, SupportApiService } from '../../api/v1';
import { UserFileV2 } from '@gea/digital-ui-lib';

@Injectable({
  providedIn: 'root',
})
export class AttachmentsService {
  maxFileSizeInBytes = 1024 * 1024 * 10; // 10MB

  constructor(private api: SupportApiService) {}

  addAttachment(ticketId: string, attachments: AttachmentBinary[]): Observable<AttachmentsIdentifier> {
    return this.api.addAttachmentsToTicket(ticketId, attachments);
  }

  getAttachments(ticketId: string): Observable<Attachment[]> {
    return this.api.getTicketAttachments(ticketId);
  }

  downloadAttachment(ticketId: string, attachmentId: string): Observable<Blob> {
    return this.api.getTicketAttachment(ticketId, attachmentId);
  }

  async convertToAttachmentBinary(attachments: UserFileV2[]): Promise<AttachmentBinary[]> {
    return Promise.all(
      attachments
        .filter((attachment) => !attachment.id)
        .map(async (attachment) => ({
          name: attachment.file?.name || '',
          binary: attachment.file ? await this.getBase64StringFromFile(attachment.file) : '',
        }))
    );
  }

  getBase64StringFromFile(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        resolve(String(reader.result).split(',')[1]);
      };

      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  }
}
