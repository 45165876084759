import { ActivatedRoute, Router } from '@angular/router';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import {
  ApiErrorResponse,
  DialogV2Service,
  ErrorHandlerV2Service,
  SavedFiles,
  SuffixIcon,
  UserFileV2,
} from '@gea/digital-ui-lib';
import { catchError, concatMap, EMPTY, Observable, ReplaySubject, Subscription, take, takeUntil } from 'rxjs';
import { TicketsService } from '../services/tickets.service';
import { AttachmentsService } from '../services/attachments.service';
import { SupportGoogleAnalyticsService } from '../services/support-google-analytics.service';
import { PermissionsService } from '../services/permissions.service';
import { ContactIdBannerService } from '../services/contact-id-banner.service';
import { ProductService } from '../services/product.service';
import {
  Attachment,
  ConversationHistory,
  ConversationInteraction,
  StatusCode,
  SupportAppPermissions,
  Ticket,
} from '../../api/v1';
import { TicketDetails } from '../models/ticket-list-entry.model';
import { ENVIRONMENT_CONFIG, EnvironmentConfiguration } from '../../../environments/models/environment.model';

@Component({
  selector: 'advance-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss'],
})
export class DetailComponent implements OnInit, OnDestroy {
  readonly StatusCode = StatusCode;

  attachmentsIcon: SuffixIcon = {
    file: '16px_file',
    label: 'TICKETS.DETAIL.ATTACHMENTS-BUTTON',
  };

  successorTicketIcon: SuffixIcon = {
    file: '16px_copy-2',
    label: 'TICKETS.DETAIL.CREATE-SUCCESSOR-BUTTON',
  };

  showDescriptionPanel = false;
  ticket!: TicketDetails;
  interactions: ConversationInteraction[] = [];
  loading = false;
  isUploadingFile = false;
  ticketId = '';
  attachmentsLoading = false;
  conversationsLoading = false;
  existingAttachments: SavedFiles[] = [];
  uploadFailedAttachments: UserFileV2[] = [];
  showAttachmentSideModal = false;
  newAttachments: UserFileV2[] = [];
  createTicketAttachmentError: ApiErrorResponse;
  hasUpdatePermission = false;
  ticketDetailTitle = '';
  showCloseIcon = true;
  subHeaderOpen = false;
  isFileRowLoading = false;

  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  paramsSub: Subscription | undefined;

  private maxDescriptionLength = 315;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private ticketsService: TicketsService,
    private errorHandlerService: ErrorHandlerV2Service,
    public translateService: TranslateService,
    private googleAnalyticsService: SupportGoogleAnalyticsService,
    public attachmentsService: AttachmentsService,
    private dialogService: DialogV2Service,
    private permissionsService: PermissionsService,
    private contactIdBannerService: ContactIdBannerService,
    private productService: ProductService,
    @Inject(ENVIRONMENT_CONFIG) private environment: EnvironmentConfiguration
  ) {
    this.loading = true;
    this.attachmentsLoading = true;
    this.conversationsLoading = true;
    this.createTicketAttachmentError = this.router.getCurrentNavigation()?.extras?.state?.['error'] as ApiErrorResponse;
    this.uploadFailedAttachments = (this.router.getCurrentNavigation()?.extras?.state?.['attachments'] as UserFileV2[]) ?? [];
  }

  ngOnInit(): void {
    this.paramsSub = this.activatedRoute.params.subscribe((params) => {
      this.ticket = {} as TicketDetails;
      this.ticketId = params['id'] as string;
      this.loading = true;
      this.attachmentsLoading = true;
      this.conversationsLoading = true;

      const getTicket$ = this.ticketsService.getTicket(this.ticketId).pipe(
        takeUntil(this.destroyed$),
        catchError((error: ApiErrorResponse) => {
          if (error.status === 404) {
            this.dialogService.open({
              title: 'UI-LIB.ROUTE-GUARD.TITLE',
              message: 'UI-LIB.ROUTE-GUARD.CONTENT',
              yes: 'UI-LIB.ROUTE-GUARD.BACK-TO-DASHBOARD',
              no: '',
              closable: false,
              hideButtons: false,
              showRejectButton: false,
              confirmCallback: this.confirmNavigateToPortal.bind(this),
            });
            return EMPTY;
          }
          this.handleError(error);
          this.loading = false;
          this.attachmentsLoading = false;
          return EMPTY;
        })
      );
      const getConversation$ = this.getConversation();
      const getAttachments$ = this.attachmentsService.getAttachments(this.ticketId).pipe(
        takeUntil(this.destroyed$),
        catchError((error: ApiErrorResponse) => {
          this.handleError(error);
          this.attachmentsLoading = false;
          return EMPTY;
        })
      );

      getTicket$
        .pipe(
          takeUntil(this.destroyed$),
          concatMap((ticket) => {
            this.handleTicketResponse(ticket);
            this.loading = false;
            if (this.existingAttachments.length === 0) {
              return getAttachments$;
            }
            this.attachmentsLoading = false;
            return EMPTY;
          })
        )
        .subscribe((attachments) => {
          this.existingAttachments = attachments.map((attachment) => this.mapAttachmentToSavedFiles(attachment));
          this.attachmentsIcon.label =
            attachments.length === 0
              ? 'TICKETS.DETAIL.ATTACHMENTS-BUTTON'
              : `${this.translateService.instant('TICKETS.DETAIL.ATTACHMENTS-BUTTON') as string} (${attachments.length})`;
          this.attachmentsLoading = false;

          if (this.uploadFailedAttachments.length > 0) {
            this.toggleAttachmentView();
          }
        });

      getConversation$.subscribe((conversation) => {
        this.interactions = conversation.interactions;
        this.conversationsLoading = false;
      });

      this.permissionsService
        .getPermissions()
        .pipe(take(1))
        .subscribe({
          next: (permissions: SupportAppPermissions) => {
            if (!permissions.hasContactId) {
              this.contactIdBannerService.show();
            }
            this.hasUpdatePermission = permissions.ticketEditable;
          },
          error: (error: ApiErrorResponse) => this.errorHandlerService.handleErrorWithPrefix(error, 'SUPPORT'),
        });

      if (this.googleAnalyticsService.loggedInUser) {
        this.googleAnalyticsService.metaPush('Create-Ticket');
      } else {
        // eslint-disable-next-line no-console
        console.warn('was not able to send ga event');
      }
    });
  }

  mapAttachmentToSavedFiles(source: Attachment): SavedFiles {
    return {
      id: source.objectId,
      fileName: source.fileName,
      canDelete: false,
    };
  }

  getConversation(): Observable<ConversationHistory> {
    return this.ticketsService.getTicketConversation(this.ticketId).pipe(
      takeUntil(this.destroyed$),
      catchError((error: ApiErrorResponse) => {
        if (error.status === 404) return EMPTY;
        this.handleError(error);
        this.conversationsLoading = false;
        return EMPTY;
      })
    );
  }

  closeAttachmentView() {
    if (this.newAttachments.length > 0) {
      this.dialogService.open({
        title: 'TICKETS.ATTACHMENTS.DISCARD-FILES.TITLE',
        message: 'TICKETS.ATTACHMENTS.DISCARD-FILES.CONTENT',
        yes: 'Discard',
        no: 'Cancel',
        closable: true,
        hideButtons: false,
        confirmCallback: () => {
          this.toggleAttachmentView();
        },
      });
    } else {
      this.toggleAttachmentView();
    }
  }

  toggleAttachmentView() {
    this.showAttachmentSideModal = !this.showAttachmentSideModal;
  }

  filesUploaded(filesUploaded: number) {
    this.attachmentsIcon.label = `${
      this.translateService.instant('TICKETS.DETAIL.ATTACHMENTS-BUTTON') as string
    } (${filesUploaded})`;
    this.reloadChat();
  }

  reloadChat() {
    this.conversationsLoading = true;
    this.getConversation().subscribe((conversation) => {
      this.interactions = conversation.interactions;
      this.conversationsLoading = false;
    });
  }

  filesChanged(newFiles: UserFileV2[]) {
    this.newAttachments = newFiles;
  }

  navigateBack() {
    this.googleAnalyticsService.viewTicketPush(this.ticketId);
    void this.router.navigate(['/tickets']);
  }

  openTicket(ticketId: string) {
    window.open('/tickets/' + ticketId, '_blank');
  }

  addTicket(predecessorTicketId?: string) {
    void this.router.navigate(['/tickets/create'], {
      queryParams: { predecessorTicketId },
    });
  }

  confirmNavigateToPortal() {
    window.location.href = this.environment.portal.redirectURL;
  }

  showSeeMoreLink() {
    if (!this.ticket?.formattedDescription) return false;
    return this.ticket?.formattedDescription.length > this.maxDescriptionLength;
  }

  uploadFiles(attachments: UserFileV2[]) {
    this.isUploadingFile = true;
    this.attachmentsService
      .convertToAttachmentBinary(attachments)
      .then((attachments) => {
        this.showCloseIcon = false;
        this.attachmentsService
          .addAttachment(this.ticketId, attachments)
          .pipe(concatMap(() => this.attachmentsService.getAttachments(this.ticketId)))
          .subscribe({
            next: (attachments) => {
              this.existingAttachments = attachments.map((attachment) => this.mapAttachmentToSavedFiles(attachment));
              this.showCloseIcon = true;
              this.isUploadingFile = false;
              this.uploadFailedAttachments = [];
              this.filesChanged(this.uploadFailedAttachments);
              this.filesUploaded(this.existingAttachments.length);
              this.subHeaderOpen = this.existingAttachments.length > 0;
            },
            error: (err: ApiErrorResponse) => {
              this.showCloseIcon = true;
              this.handleError(err);
            },
          });
      })
      .catch((error: ApiErrorResponse) => this.handleError(error));
  }

  downloadAttachment(fileInfos: (string | undefined)[]) {
    this.isFileRowLoading = true;
    if (fileInfos[0]) {
      this.attachmentsService.downloadAttachment(this.ticketId, fileInfos[0]).subscribe({
        next: (file: Blob) => {
          this.downloadFromBrowser(file, fileInfos[1] ?? '');
          this.isFileRowLoading = false;
        },
        error: (error: ApiErrorResponse) => {
          this.errorHandlerService.handleErrorWithPrefix(error, 'SUPPORT');
          this.isFileRowLoading = false;
        },
      });
    }
  }

  downloadFromBrowser(file: Blob, fileName: string) {
    const anchorElement = document.createElement('a');
    const objectUrl = URL.createObjectURL(file);
    anchorElement.href = objectUrl;
    anchorElement.download = fileName;
    anchorElement.click();
    URL.revokeObjectURL(objectUrl);
  }

  private handleTicketResponse(ticket: Ticket) {
    this.ticket = ticket as TicketDetails;
    this.ticketDetailTitle = `${ticket.subject} #${ticket.id}`;
    const products = this.ticket.products;
    this.ticket.mainProduct = this.productService.getMainProductDisplayName(products);
  }

  private handleError(error: ApiErrorResponse) {
    this.errorHandlerService.handleErrorWithPrefix(error, 'SUPPORT');
    this.loading = false;
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
    this.paramsSub?.unsubscribe();
  }
}
