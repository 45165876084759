/**
 * Support app
 * Support app
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type StatusCode = 'NotFound' | 'Open_1' | 'InProcess_2' | 'Completed_5' | 'Withdrawn_Z3' | 'Planned_Z8' | 'InDelivery_Z8' | 'WaitingForInternal_Y1' | 'WaitingFor3rdParty_Y2' | 'WaitingForCustomer_Z9';

export const StatusCode = {
    NOT_FOUND: 'NotFound' as StatusCode,
    OPEN: 'Open_1' as StatusCode,
    IN_PROCESS: 'InProcess_2' as StatusCode,
    COMPLETED: 'Completed_5' as StatusCode,
    WITHDRAWN: 'Withdrawn_Z3' as StatusCode,
    PLANNED: 'Planned_Z8' as StatusCode,
    IN_DELIVERY: 'InDelivery_Z8' as StatusCode,
    WAITING_FOR_INTERNAL: 'WaitingForInternal_Y1' as StatusCode,
    WAITING_FOR_3RD_PARTY: 'WaitingFor3rdParty_Y2' as StatusCode,
    WAITING_FOR_CUSTOMER: 'WaitingForCustomer_Z9' as StatusCode
};

