import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, InjectionToken, NgModule } from '@angular/core';
import { MsalRedirectComponent } from '@azure/msal-angular';
import { HttpClient, provideHttpClient } from '@angular/common/http';

import { NgxsModule } from '@ngxs/store';
import {
  ActionRequiredState,
  AppEnvironmentState,
  AttachmentsV2Module,
  BannerModule,
  ButtonV2Module,
  DialogV2Module,
  GeaMissingTranslationHandler,
  GeaTranslateHttpLoader,
  HeaderConfig,
  HeaderModule,
  IconModule,
  InputV2Module,
  LinkV2Module,
  MultiSelectV2Module,
  PageContentLayoutComponent,
  PageFooterComponent,
  PageHeaderModule,
  PageLayoutModule,
  PermissionsState,
  SearchBarV2Module,
  SelectV2Module,
  SharedModule,
  SidebarV2Module,
  SideModalV2Module,
  SnackbarModule,
  TableV2Module,
  TextfieldV2Module,
  ToggleV2Module,
  UserState,
} from '@gea/digital-ui-lib';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { MissingTranslationHandler, TranslateLoader, TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { TooltipModule } from 'primeng/tooltip';
import { AccordionModule } from 'primeng/accordion';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AssetApiService } from './tickets/services/asset-api.service';
import { TicketsComponent } from './tickets/tickets.component';
import { TicketsService } from './tickets/services/tickets.service';
import { CreateTicketComponent } from './tickets/create-ticket/create-ticket.component';
import { DetailComponent } from './tickets/detail/detail.component';
import { CommentComponent } from './tickets/detail/comment/comment.component';
import { PermissionsService } from './tickets/services/permissions.service';
import { ServiceCategoryService } from './tickets/services/service-category.service';
import { AttachmentsService } from './tickets/services/attachments.service';
import { StatusComponent } from './tickets/ticket-status/status/status.component';
import { StatusRendererComponent } from './tickets/ticket-status/status-renderer/status-renderer.component';
import { NoMembershipsComponent } from './tickets/no-content/no-memberships/no-memberships.component';
import { NoEntriesComponent } from './tickets/no-content/no-entries/no-entries.component';
import { InfoBoxComponent } from './tickets/detail/info-box/info-box.component';
import { Configuration, SupportApiService } from './api/v1';
import { ENVIRONMENT_CONFIG, EnvironmentConfiguration } from '../environments/models/environment.model';
import { NgIf } from '@angular/common';
import { InteractiveBoxComponent } from './tickets/detail/interactive-box/interactive-box.component';
import { RelatedTicketsComponent } from './tickets/detail/related-tickets/related-tickets.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

export function HttpLoaderFactory(http: HttpClient, environment: EnvironmentConfiguration) {
  return new GeaTranslateHttpLoader(http, environment.localizationStorageURL, ['i18n/1/support/']);
}

export const HEADER_CONFIG = new InjectionToken<HeaderConfig>('HeaderConfig');

@NgModule({
  declarations: [
    AppComponent,
    TicketsComponent,
    CreateTicketComponent,
    DetailComponent,
    CommentComponent,
    StatusComponent,
    StatusRendererComponent,
    NoMembershipsComponent,
    NoEntriesComponent,
    InteractiveBoxComponent,
    RelatedTicketsComponent,
    InfoBoxComponent,
  ],
  imports: [
    NgxsModule.forRoot([UserState, ActionRequiredState, AppEnvironmentState, PermissionsState], {
      compatibility: {
        strictContentSecurityPolicy: true,
      },
    }),
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    SelectV2Module,
    ButtonV2Module,
    ButtonV2Module,
    IconModule,
    TextfieldV2Module,
    SharedModule,
    TooltipModule,
    NgxSkeletonLoaderModule,
    TranslateModule.forRoot({
      extend: true,
      defaultLanguage: 'en-US',
      useDefaultLang: false,
      loader: {
        deps: [HttpClient, ENVIRONMENT_CONFIG],
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
      },
      missingTranslationHandler: { provide: MissingTranslationHandler, useClass: GeaMissingTranslationHandler },
    }),
    HeaderModule.forRoot(HEADER_CONFIG),
    ReactiveFormsModule,
    NgxSkeletonLoaderModule,
    AttachmentsV2Module,
    PageHeaderModule,
    PageLayoutModule,
    BannerModule,
    PageHeaderModule,
    ButtonV2Module,
    LinkV2Module,
    TableV2Module,
    ButtonV2Module,
    SearchBarV2Module,
    MultiSelectV2Module,
    IconModule,
    InputV2Module,
    SideModalV2Module,
    PageLayoutModule,
    SidebarV2Module,
    SnackbarModule,
    AccordionModule,
    DialogV2Module,
    InputV2Module,
    ToggleV2Module,
    DialogV2Module,
    IconModule,
    NgIf,
    IconModule,
    NgxSkeletonLoaderModule,
    PageContentLayoutComponent,
    PageFooterComponent,
    ProgressSpinnerModule,
  ],
  providers: [
    provideHttpClient(),
    TranslatePipe,
    TicketsService,
    PermissionsService,
    AttachmentsService,
    ServiceCategoryService,
    AssetApiService,
    SupportApiService,
    {
      provide: 'baseUrl',
      useFactory: (env: EnvironmentConfiguration) => env.portal.baseURL ?? env.baseURL,
      deps: [ENVIRONMENT_CONFIG],
    },
    {
      provide: 'assetBaseUrl',
      useFactory: (env: EnvironmentConfiguration) => (env.asset.baseURL ?? env.baseURL) + 'asset/v1',
      deps: [ENVIRONMENT_CONFIG],
    },
    {
      provide: 'subscriptionKey',
      useValue: '',
    },
    {
      provide: 'iconPath',
      useFactory: (env: EnvironmentConfiguration) => env.iconStorageURL,
      deps: [ENVIRONMENT_CONFIG],
    },
    {
      provide: 'appContentPath',
      useFactory: (env: EnvironmentConfiguration) => env.appContentStorageURL,
      deps: [ENVIRONMENT_CONFIG],
    },
    {
      provide: 'storageURL',
      useFactory: (env: EnvironmentConfiguration) => env.localizationStorageURL,
      deps: [ENVIRONMENT_CONFIG],
    },

    {
      provide: Configuration,
      useFactory: (env: EnvironmentConfiguration) =>
        new Configuration({
          basePath: (env.support.baseURL ?? env.baseURL) + 'support/v1',
        }),
      deps: [ENVIRONMENT_CONFIG],
      multi: false,
    },
    { provide: 'googleTagManagerId', useValue: 'GTM-M64DB78' },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
