import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'advance-no-entries',
  templateUrl: './no-entries.component.html',
  styleUrls: ['./no-entries.component.scss'],
})
export class NoEntriesComponent {
  constructor(private router: Router) {}

  addTicket() {
    void this.router.navigate(['/tickets/create']);
  }
}
