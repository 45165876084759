/**
 * Support app
 * Support app
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CreateTicketEventResponseConversationChangesInner { 
    action: string;
    direction: CreateTicketEventResponseConversationChangesInner.DirectionEnum;
    contentHtml: string;
    creator: string;
}
export namespace CreateTicketEventResponseConversationChangesInner {
    export type DirectionEnum = 'INBOUND' | 'OUTBOUND';
    export const DirectionEnum = {
        Inbound: 'INBOUND' as DirectionEnum,
        Outbound: 'OUTBOUND' as DirectionEnum
    };
}


